<template>
  <div class="content">
    <Slider :slides="slides" v-slot="{ currentSlide }">
    <!-- <a :href="currentSlide.link" target="_blank"> -->
        <img :alt="currentSlide.title" :title="currentSlide.title" :src="currentSlide.img">
    <!-- </a> -->
    </Slider>
    <router-view></router-view>
    <TheContact />
  </div>
</template>

<script>

export default {
  name: 'News',
  data() {
    return {
       slides: [
        {
          title: '新闻资讯',
          img: require('@/assets/news.png'),
        }
      ],
    }
  },
  methods: {
  }
}
</script>

<style scoped>

</style>